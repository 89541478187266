@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}
.swiper-cube-shadow{
  display: none;
}

.swiper-slide-shadow-cube{
  display: none;

}


html{
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}